import { i18n } from '@lingui/core';
import { messages as messagesEn } from '../locales/en/messages.mjs';
import { messages as messagesNb } from '../locales/nb/messages.mjs';
import { messages as messagesFi } from '../locales/fi/messages.mjs';
import { messages as messagesDa } from '../locales/da/messages.mjs';

const locales = {
    en: messagesEn,
    nb: messagesNb,
    fi: messagesFi,
    da: messagesDa,
};

const loadAndActivateI18n = (locale = 'en') => {
    i18n.load({ [locale]: locales[locale] });
    i18n.activate(locale);
};

export default loadAndActivateI18n;
