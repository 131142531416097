import { html, render, css } from 'lit';
import WarpElement from '@warp-ds/elements-core';
import pulseTracker from '@finn-no/pulse-sdk';
import '@ungap/global-this';
import '@finn-no/messaging-eid-verification-modal';
import { i18n } from '@lingui/core';
import loadAndActivateI18n from './i18n.js';
import '@warp-ds/elements/components/button';

class FinnContactButton extends WarpElement {
    static styles = [
        WarpElement.styles,
        css`
            @unocss-placeholder;
        `,
    ];

    static get properties() {
        // reason for change: https://newbedev.com/syntaxerror-unexpected-token-expected-a-or-a-after-a-parameter-declaration-in-safari
        return {
            buttonText: { type: String },
            buttonVariant: { type: String },
            size: { type: String },
            trackingObjectName: { type: String },
            eidVerified: { type: Boolean },
            isAdOwner: { type: Boolean },
            loginId: { type: Number },
            preview: { type: Boolean },
            requireEid: { type: Boolean },
            showError: { type: Boolean },
            locale: { type: String },
        };
    }

    constructor() {
        super();
        this.buttonVariant = 'primary';
        this.size = '';
        this.trackingObjectName = ''; // this attribute is for the sending name as an additional info with the event in pulse tracker
        this.adId = '';
        this.eidVerified = false;
        this.isAdOwner = false;
        this.loginId = '';
        this.preview = false;
        this.requireEid = false;
        this.showError = false;
        this.unknownError = false;
        this.brand = 'FINN';
        this.locale = '';
    }

    connectedCallback() {
        super.connectedCallback();
        // Fetch the meta data in the script tag rendered by the server
        const element = document.getElementById('contact-button-data');
        try {
            const data = JSON.parse(element.textContent);
            this.url = data.url;
            this.adId = data.adId;
            this.isAdOwner = data.isAdOwner;
            this.eidVerified = data.eidVerified;
            this.loginId = data.loginId;
            this.requireEid = data.requireEid;
            this.name = data.name;
            this.preview = data.preview;
            this.showError = data.showError;
            this.unknownError = data.unknownError;
            this.brand = data.brand;
            this.locale = data.locale;

            loadAndActivateI18n(data.locale);

            if (this.loginId && this.isAdOwner) {
                this.showError = JSON.parse(
                    window.localStorage.getItem('showError'),
                );
                window.localStorage.removeItem('showError');
            } else if (this.unknownError) {
                this.showError = JSON.parse(
                    window.localStorage.getItem('showError'),
                );
                window.localStorage.removeItem('showError');
            } else {
                this.showError = false;
                window.localStorage.setItem('showError', this.showError);
                window.localStorage.removeItem('showError');
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
            this.unknownError = true;
        }
    }

    getButtonText() {
        return this.buttonText !== undefined
            ? this.buttonText
            : i18n.t({
                  id: 'contact-button.button-text.default',
                  message: 'Send message',
                  comment: 'Button text when not provided by user',
              });
    }

    buttonTemplate() {
        const errorText = i18n.t({
            id: 'contact-button.template.title',
            message: 'An unknown error occurred',
            comment: 'Title/popover used when unknown error occurs',
        });

        return html`
            <w-button
                variant="${this.buttonVariant.toLowerCase()}"
                title=${this.unknownError ? errorText : ''}
                @click="${this.errorCheck}"
                full-width
                ?small="${typeof this.size !== 'undefined' &&
                this.size.toLowerCase() === 'small'}"
            >
                ${this.getButtonText()}
            </w-button>
        `;
    }

    previewTemplate() {
        return html`
            <w-button
                title="preview button"
                variant="${this.buttonVariant.toLowerCase()}"
                @click="${this.openPreviewModal}"
                full-width
                ?small="${typeof this.size !== 'undefined' &&
                this.size.toLowerCase() === 'small'}"
            >
                ${this.getButtonText()}
            </w-button>
        `;
    }

    linkTemplate() {
        if (this.requireEid && !this.eidVerified) {
            return html`
                <w-button
                    variant="${this.buttonVariant.toLowerCase()}"
                    @click="${this.openVerificationModal}"
                    full-width
                    ?small="${typeof this.size !== 'undefined' &&
                    this.size.toLowerCase() === 'small'}"
                >
                    ${this.getButtonText()}
                </w-button>
                <div id="eid-modal-wrapper">
                    <messaging-eid-modal
                        id="eid-modal"
                        should-open="false"
                        eid-verification-url="${this.url}"
                        locale="${this.locale}"
                        brand="${this.brand}"
                    ></messaging-eid-modal>
                </div>
            `;
        }

        return html`
            <w-button
                rel="nofollow"
                href="${this.url}"
                variant="${this.buttonVariant.toLowerCase()}"
                @click="${this.trackButtonClick}"
                full-width
                ?small="${typeof this.size !== 'undefined' &&
                this.size.toLowerCase() === 'small'}"
            >
                ${this.getButtonText()}
            </w-button>
        `;
    }

    errorTemplate() {
        let errorTemplate;
        if (this.showError && this.isAdOwner) {
            errorTemplate = html`
                <p
                    class="s-text-negative text-xs text-center mt-8"
                    id="send-message-own-ad-error-msg"
                >
                    ${i18n.t({
                        id: 'contact-button.error.is-ad-owner',
                        message: "Oh my! You can't send a message to yourself.",
                        comment:
                            'Text shown when user is ad owner, after button',
                    })}
                </p>
            `;
        } else if (this.showError) {
            errorTemplate = html`
                <p
                    class="s-text-negative text-xs text-center mt-8"
                    id="send-message-own-ad-error-msg"
                >
                    ${i18n.t({
                        id: 'contact-button.error.unknown-error',
                        message: 'Oh, my! Something went wrong.',
                        comment: 'Text shown when unknown error, after button',
                    })}
                </p>
            `;
        }
        return errorTemplate;
    }

    getFinalTemplate() {
        if (this.preview === 'true' || this.preview === true) {
            return html`${this.previewTemplate()}`;
        }
        if (this.isAdOwner || this.unknownError) {
            return html`${this.buttonTemplate()}${this.errorTemplate()}`;
        }
        return html`${this.linkTemplate()}`;
    }

    render() {
        return html` <div>${this.getFinalTemplate()}</div> `;
    }

    errorCheck() {
        this.showError = true;
    }

    openPreviewModal() {
        const myEvent = new CustomEvent('open-modal', {
            composed: true,
            bubbles: true,
        });
        this.dispatchEvent(myEvent);
    }

    trackButtonClick() {
        if (!this.loginId) {
            window.localStorage.setItem('showError', true);
        }
        // Calling the tracker is not required when the user is the ad owner.
        const pulseObj = {
            type: 'Click',
            intent: 'Create',
            name: 'Send message',
            object: {
                id: 'sendMessageButton',
                type: 'UIElement',
                elementType: 'Button',
                items: [
                    {
                        type: 'ClassifiedAd',
                        id: this.adId,
                        contentId: this.adId,
                        name: 'n/a',
                        category: 'n/a',
                        hasExtendedProfile: false,
                    },
                ],
                page: {
                    type: 'ClassifiedAd',
                },
            },
            target: {
                id: 'id',
                type: 'Message',
            },
        };

        if (this.trackingObjectName) {
            pulseObj.object.name = this.trackingObjectName;
        }
        pulseTracker.trackEvent(pulseObj);
    }

    openVerificationModal() {
        const modal = this.shadowRoot.getElementById('eid-modal');
        const parent = this.shadowRoot.getElementById('eid-modal-wrapper');
        modal.setAttribute('should-open', 'true');
        render(modal, parent);

        if (document.getElementById('sexy-sidebar') !== null) {
            document.getElementById('sexy-sidebar').style.zIndex = '20'; // parent container 'rightPaneContainer' is in mFinn
        }

        document.addEventListener('onModalClose', () => {
            const renderedModal = this.shadowRoot.getElementById('eid-modal');
            const renderedParent =
                this.shadowRoot.getElementById('eid-modal-wrapper');
            if (renderedModal) {
                renderedModal.setAttribute('should-open', 'false');
                render(renderedModal, renderedParent);
                if (document.getElementById('sexy-sidebar') !== null) {
                    document.getElementById('sexy-sidebar').style.zIndex = '3';
                }
            }
        });
        this.trackButtonClick();
    }
}

customElements.define('finn-contact-button', FinnContactButton);
